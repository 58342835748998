@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap');

body {
  font-family: 'Comic Neue', cursive;
}

.header {
  font-family: 'Bangers', cursive;
  background-color: #ff4444; /* Example background color */
  color: white; /* Example text color */
  padding: 10px 20px;
  text-align: center;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.6); /* Bigger box shadow */
}

.header h1 {
  letter-spacing: 4px; /* Increased letter spacing */
}

.button {
  background-color: yellow; /* Bright red */
  color: black;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  font-family: 'Bangers', cursive;
  font-size: 20px;
  box-shadow: 3px 3px 0 black;
  transition: transform 0.1s ease-in-out;
  font-size: "16px";
}

.button:hover {
  transform: scale(1.1);
}

.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-MainLogo {
  height: 15vmin;
}

.App-logoL2R {
  height: 20vmin;
  pointer-events: none;
}

.App-logoR2L {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-MainLogo {
    animation: zoom-in-zoom-out infinite 3s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoL2R {
    animation: Logo-spinL2R infinite 5s ease-in-out;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoR2L {
    animation: Logo-spinR2L infinite 5s ease-in-out;
  }
}
.App-header {
  min-height: 21vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logos { 
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

.App-link {
  color: #f1a20d;
}

@keyframes Logo-spinL2R {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Logo-spinR2L {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
  }

/* Spinner CSS */
.spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adds some space above the spinner */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
